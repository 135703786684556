.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: rgb(246, 249, 250);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
}

.App-link {
  color: #282c34;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.headerLogo {
  width: 100px;
  /* height: 168.5px; */
}

.headerLogoContainer {
  display: flex;
  justify-content: left;
  align-items: center;
}

.groupLabel {
  margin-top: 5px;
  /* font-style: italic; */
  font-size: 10pt;
  font-weight: bold;
  color: gray; 
}
.groupLabelCenter {
  margin-top: 5px;
  text-align: center;
  font-style: italic;
  font-size: 10pt;
  font-weight: bold;
  color: gray; 
}